import { useEffect, useState } from 'react'
import { useAxios } from 'api/Axios'
import { useSession } from 'api/Session'
import { SelectObjectType } from 'types/select'
import { ColDef } from 'ag-grid-community'
import { CommDiv, BtnDiv, Container, MobileHeaderDiv } from './Home.styles'
import { useAlert } from 'components/Elements/Alert/Alert'
import {
    DataType,
    HeaderSectionProps,
    FilterSectionProps,
    ButtonSectionProps,
} from 'types/home.type'
import { CommCodesType } from 'types/commCodes.type'
import { useNavigate, Link } from 'react-router-dom'
import { formatDepositAndRent } from 'components/CommFuc/Formatter'
import { Img } from 'components/Elements/Header/Header.styles'

import Grid from 'components/Grid/Grid'
import logo2 from 'assets/img/bangdoriLogo.png'
import Header from 'components/Elements/Header/Header'
import ACheckSelector from 'components/Elements/ACheckSelector/ACheckSelector'
import ARangeSelector from 'components/Elements/ARangeSelector/ARangeSelector'
import AButton from 'components/Elements/AButton/AButton'

const PLACEHOLDER_MAP = {
    PR001: '거래종류',
    PR002: '거래방식',
    PR003: '방수',
    PR004: '욕실수',
    PR005: '방향',
    PR006: '입주일',
    PR007: '상태',
    PR008: '추가옵션',
} as const

const COMM_CODE_TYPES = [
    'PR001',
    'PR002',
    'PR003',
    'PR004',
    'PR005',
    'PR006',
    'PR007',
    'PR008',
] as const

const styles = {
    link: {
        textDecoration: 'none',
        color: '#333',
        fontWeight: '500',
        fontSize: '16px',
        marginTop: '5px',
        marginBottom: '15px',
    },
}

const HeaderSection = ({ mobileSize, handleInputChange }: HeaderSectionProps) =>
    mobileSize ? (
        <MobileHeaderDiv>
            <Link style={styles.link} to="/home">
                <Img src={logo2} alt="로고" />
            </Link>
            <Link style={styles.link} to="/map">
                맵검색
            </Link>
        </MobileHeaderDiv>
    ) : (
        <Header handleInputChange={handleInputChange} />
    )

const FilterSection = ({
    handleRangeChange,
    codeItems,
    handleChangeSelector,
}: FilterSectionProps) => (
    <CommDiv>
        <ARangeSelector
            onChange={range => handleRangeChange('deposit', range)}
            min={0}
            max={99999}
            placeholder="보증금"
            mr="10px"
        />
        <ARangeSelector
            onChange={range => handleRangeChange('monthlyRent', range)}
            min={0}
            max={99999}
            placeholder="월세"
            mr="10px"
        />
        {COMM_CODE_TYPES.map(codeType => (
            <ACheckSelector
                key={codeType}
                options={codeItems[codeType] || []}
                placeholder={PLACEHOLDER_MAP[codeType]}
                onChange={selectedItems =>
                    handleChangeSelector(codeType, selectedItems)
                }
                mr="10px"
            />
        ))}
    </CommDiv>
)

const ButtonSection = ({
    handleCrrection,
    handleCopy,
    handleUpdateNewDtm,
    handleDeleteProd,
}: ButtonSectionProps) => (
    <BtnDiv>
        <AButton onClick={handleCrrection} width="60px" mr="8px">
            수정
        </AButton>
        <AButton onClick={handleCopy} width="60px" mr="8px">
            복사
        </AButton>
        <AButton onClick={handleUpdateNewDtm} width="60px" mr="8px">
            UP
        </AButton>
        <AButton onClick={handleDeleteProd} width="60px">
            삭제
        </AButton>
    </BtnDiv>
)

const mapCommCodeToSelectOptions = (
    codeList: CommCodesType[],
): SelectObjectType[] =>
    codeList.map(item => ({
        text: item.cdNm,
        value: item.dtlCd,
    }))

function Home() {
    const [codeItems, setCodeItems] = useState<
        Record<string, SelectObjectType[]>
    >({})
    const [rowData, setRowData] = useState<DataType[]>([])
    const [phoneNo1, setPhoneNo1] = useState('')
    const [phoneNo2, setPhoneNo2] = useState('')
    const [selectedItem, setSelectedItem] = useState<DataType[]>([])
    const [filteredData, setFilteredData] = useState<DataType[]>([])
    const [filters, setFilters] = useState<Record<string, string[]>>({})
    const [searchText, setSearchText] = useState('')
    const [rangeFilter, setRangeFilter] = useState({
        deposit: { min: 0, max: 99999 },
        monthlyRent: { min: 0, max: 99999 },
    })
    const [mobileSize, setMobileSize] = useState(false)
    const [headers, setHeaders] = useState<ColDef[]>([])

    const [defaultHeaders] = useState<ColDef[]>([
        {
            field: 'prodNo',
            headerName: '매물번호',
            width: 100,
        },
        {
            field: 'title',
            headerName: '매물명',
            width: 350,
        },
        {
            field: 'unitNo',
            headerName: '호수',
            width: 100,
        },
        {
            field: 'depositAndMonthlyRent',
            headerName: '보증금/월세',
            width: 400,
            valueGetter: params => {
                const tradeType = params.data.tradeType
                const item = params.data
                if (['10', '20', '30', '40'].includes(tradeType)) {
                    return formatDepositAndRent(item.depositAndMonthlyRent)
                } else if (tradeType === '50') {
                    return formatDepositAndRent(item.salePricdAndDepoAndRent)
                } else if (tradeType === '60') {
                    return formatDepositAndRent(
                        item.depositAndMonthlyRentAndPreFee,
                    )
                }
                return ''
            },
        },
        {
            field: 'entrancePwd',
            headerName: '비밀번호(세대)',
            width: 200,
        },
        {
            field: 'unitPwd',
            headerName: '비밀번호(현관)',
            width: 200,
        },
        {
            field: 'prodAddr',
            headerName: '주소',
            width: 350,
        },
        {
            field: 'phoneNo1',
            headerName: '연락처1',
            width: 250,
            valueFormatter: params => {
                const phoneNumber = params.value
                if (!phoneNumber) return ''
                const cleaned = phoneNumber.replace(/[^\d]/g, '')
                if (cleaned.length === 10) {
                    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                } else if (cleaned.length === 11) {
                    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                }
                return phoneNumber
            },
        },
        {
            field: 'phoneNo2',
            headerName: '연락처2',
            width: 250,
            valueFormatter: params => {
                const phoneNumber = params.value
                if (!phoneNumber) return ''
                const cleaned = phoneNumber.replace(/[^\d]/g, '')
                if (cleaned.length === 10) {
                    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
                } else if (cleaned.length === 11) {
                    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                }
                return phoneNumber
            },
        },
        {
            field: 'etc',
            headerName: '기타정보',
            width: 340,
        },
    ])

    const { get, post } = useAxios()
    const { setSession, getSession } = useSession()
    const navigate = useNavigate()

    const { open } = useAlert({
        icon: 'error',
        title: '알림',
        text: '선택된 매물이 없습니다.',
    })
    const { open: moreSelectedItem } = useAlert({
        icon: 'error',
        title: '알림',
        text: '하나의 매물만 선택 해주세요.',
    })
    const { open: successUpdate } = useAlert({
        icon: 'success',
        title: '알림',
        text: '최신일자 변경이 완료되었습니다.',
    })
    const { open: successDelete } = useAlert({
        icon: 'success',
        title: '알림',
        text: '삭제가 완료되었습니다.',
    })

    const COMM_CODE_MAP: Record<string, keyof DataType> = {
        PR001: 'type',
        PR002: 'tradeType',
        PR003: 'roomCd',
        PR004: 'bathCd',
        PR005: 'directionCd',
        PR006: 'moveInCd',
        PR007: 'rcmCd',
        PR008: 'remarkCd',
    }

    const selectProduct = async () => {
        const res = await get<DataType>('/api/product/products')
        if (res.valid && res.result) {
            setRowData(res.result.LIST)
        }
    }

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768
            const filteredHeaders = defaultHeaders.filter(header =>
                isMobile
                    ? header.field !== 'etc' && header.field !== 'prodNo'
                    : true,
            )
            setHeaders(filteredHeaders)
            setMobileSize(isMobile)
        }

        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [defaultHeaders])

    useEffect(() => {
        async function getCommCode() {
            const response = await get<CommCodesType>(
                '/api/comm/code/commCodes',
            )
            selectProduct()

            if (response.valid && response.result) {
                setSession('commCodes', response.result.LIST)
                const processedCodeItems = COMM_CODE_TYPES.reduce(
                    (acc, codeType) => {
                        acc[codeType] = mapCommCodeToSelectOptions(
                            getSession('commCodes')[codeType].slice(1),
                        )
                        return acc
                    },
                    {} as Record<string, SelectObjectType[]>,
                )
                setCodeItems(processedCodeItems)
            }
        }
        getCommCode()
    }, [])

    useEffect(() => {
        const filteredData = rowData.filter(item => {
            const deposit = item.deposit ? Number(item.deposit) : 0
            const rent = item.monthlyRent ? Number(item.monthlyRent) : 0

            const isWithinDepositRange =
                deposit >= rangeFilter.deposit.min &&
                deposit <= rangeFilter.deposit.max
            const isWithinRentRange =
                rent >= rangeFilter.monthlyRent.min &&
                rent <= rangeFilter.monthlyRent.max
            const matchesSearchText = searchText
                ? Object.values(item).some(value =>
                      value
                          ?.toString()
                          .toLowerCase()
                          .includes(searchText.toLowerCase()),
                  )
                : true

            return (
                Object.entries(filters).every(([key, selectedValues]) => {
                    const mappedKey = COMM_CODE_MAP[key]
                    const itemValue = item[mappedKey]
                    if (key === 'PR008') {
                        return (
                            selectedValues.length === 0 ||
                            selectedValues.some(value =>
                                itemValue.includes(value),
                            )
                        )
                    }
                    return (
                        selectedValues.length === 0 ||
                        selectedValues.includes(itemValue)
                    )
                }) &&
                isWithinDepositRange &&
                isWithinRentRange &&
                matchesSearchText
            )
        })
        setFilteredData(filteredData)
    }, [filters, rangeFilter, rowData, searchText])

    const handleChangeSelector = (key: string, selectedItems: string[]) => {
        setFilters(prev => ({ ...prev, [key]: selectedItems }))
    }

    const handleRangeChange = (
        type: 'deposit' | 'monthlyRent',
        range: { min: number; max: number },
    ) => {
        setRangeFilter(prev => ({ ...prev, [type]: range }))
    }

    const handleSelectionCheckBox = (item: unknown[]) => {
        const row = item as DataType[]
        setSelectedItem(row)
    }

    const handleRowClick = (items: unknown) => {
        const data = items as DataType
        if (data.phoneNo1) setPhoneNo1(data.phoneNo1)
        if (data.phoneNo2) setPhoneNo2(data.phoneNo2)
    }

    const handleCellClick = (colId: string) => {
        const extractNumbers = (phoneNumber: string) => {
            return phoneNumber.replace(/[^0-9]/g, '')
        }

        if (colId === 'phoneNo1' && phoneNo1) {
            const cleanedPhoneNo1 = extractNumbers(phoneNo1)
            window.location.href = `tel:${cleanedPhoneNo1}`
        }
        if (colId === 'phoneNo2' && phoneNo2) {
            const cleanedPhoneNo2 = extractNumbers(phoneNo2)
            window.location.href = `tel:${cleanedPhoneNo2}`
        }
    }

    const handleCrrection = () => {
        if (selectedItem.length === 0) {
            open()
            return
        }
        if (selectedItem.length > 1) {
            moreSelectedItem()
            return
        }

        const itemToUpdate = { ...selectedItem[0], chgResn: 'U' }
        const navigatePath = {
            '10': '/product/registration',
            '20': '/product/registration',
            '30': '/product/registration',
            '40': '/product/registration',
            '50': '/bargain/registration',
            '60': '/rent/registration',
        }[selectedItem[0].tradeType]

        if (navigatePath) {
            navigate(navigatePath, { state: { item: itemToUpdate } })
        }
    }

    const handleCopy = () => {
        if (selectedItem.length === 0) {
            open()
            return
        }
        if (selectedItem.length > 1) {
            moreSelectedItem()
            return
        }

        const itemToCopy = { ...selectedItem[0], chgResn: 'C' }
        const navigatePath = {
            '10': '/product/registration',
            '20': '/product/registration',
            '30': '/product/registration',
            '40': '/product/registration',
            '50': '/bargain/registration',
            '60': '/rent/registration',
        }[selectedItem[0].type]

        if (navigatePath) {
            navigate(navigatePath, { state: { item: itemToCopy } })
        }
    }

    const handleUpdateNewDtm = async () => {
        if (selectedItem.length === 0) {
            open()
            return
        }

        try {
            await Promise.all(
                selectedItem.map(item =>
                    post<void>('/api/product/updateNewDtm', {
                        prodNo: item.prodNo,
                        userNo: getSession('userNo'),
                    }),
                ),
            )
            successUpdate(() => {
                selectProduct()
            })
        } catch (error) {
            console.error('Error updating new DTM:', error)
        }
    }

    const handleDeleteProd = async () => {
        if (selectedItem.length === 0) {
            open()
            return
        }

        try {
            await Promise.all(
                selectedItem.map(item =>
                    post<void>('/api/product/deleteProduct', {
                        prodNo: item.prodNo,
                    }),
                ),
            )
            successDelete(() => {
                selectProduct()
            })
        } catch (error) {
            console.error('Error deleting products:', error)
        }
    }

    const handleInputChange = (value: string) => {
        setSearchText(value)
    }

    return (
        <>
            <HeaderSection
                mobileSize={mobileSize}
                handleInputChange={handleInputChange}
            />
            <Container>
                {!mobileSize && (
                    <>
                        <FilterSection
                            handleRangeChange={handleRangeChange}
                            codeItems={codeItems}
                            handleChangeSelector={handleChangeSelector}
                        />
                        <ButtonSection
                            handleCrrection={handleCrrection}
                            handleCopy={handleCopy}
                            handleUpdateNewDtm={handleUpdateNewDtm}
                            handleDeleteProd={handleDeleteProd}
                        />
                    </>
                )}
                <Grid
                    height={mobileSize ? '700px' : '650px'}
                    onCellClick={handleCellClick}
                    onRowClick={handleRowClick}
                    onselectionchange={handleSelectionCheckBox}
                    items={filteredData}
                    columns={headers}
                />
            </Container>
        </>
    )
}

export default Home
